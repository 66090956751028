var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.deletingAccount, "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { depressed: "", color: "error" } }, on),
                [_vm._v("delete")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.deleteAccountDialog,
        callback: function ($$v) {
          _vm.deleteAccountDialog = $$v
        },
        expression: "deleteAccountDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center w-100" },
              [
                _c("v-icon", [_vm._v("warning")]),
                _c(
                  "span",
                  { staticClass: "text-uppercase secondary--text ml-1" },
                  [_vm._v("delete account")]
                ),
                _c("v-spacer"),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-alert",
                {
                  staticClass: "mt-5",
                  attrs: {
                    outlined: "",
                    color: "error",
                    prominent: "",
                    type: "warning",
                  },
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Here is what happens when you delete your account"),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "mt-3" },
                    _vm._l(
                      _vm.accountDeleteConsequences,
                      function (item, index) {
                        return _c("li", { key: index, staticClass: "mt-2" }, [
                          _c("span", { staticClass: "subtitle-2" }, [
                            _vm._v(" " + _vm._s(item) + " "),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                  _c("v-divider", {
                    staticClass: "my-4 secondary",
                    staticStyle: { opacity: "0.22" },
                  }),
                  _c("v-checkbox", {
                    staticClass: "secondary--text",
                    attrs: {
                      disabled: _vm.deletingAccount,
                      color: "error",
                      label: "I understand",
                    },
                    model: {
                      value: _vm.agreeBox,
                      callback: function ($$v) {
                        _vm.agreeBox = $$v
                      },
                      expression: "agreeBox",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card-actions", [
            _c("div", { staticClass: "d-flex flex-column w-100" }, [
              _c(
                "div",
                { staticClass: "d-flex align-center justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.deletingAccount,
                        color: "secondary",
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.deleteAccountDialog = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.agreeBox || _vm.deletingAccount,
                        loading: _vm.deletingAccount,
                        text: "",
                        color: "error",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteAccount()
                        },
                      },
                    },
                    [_vm._v("delete")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }